<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 240 400"
  ><path
    fill="currentColor"
    fill-rule="evenodd"
    d="M0 32C0 14 14 0 32 0h176c18 0 32 14 32 32v96c0 18-14 32-32 32h-16c-18 0-32 14-32 32v16c0 18 14 32 32 32h16c18 0 32 14 32 32v96c0 18-14 32-32 32H32c-18 0-32-14-32-32v-16c0-18 14-32 32-32h96c18 0 32-14 32-32v-16c0-18-14-32-32-32h-16c-18 0-32-14-32-32v-16c0-18 14-32 32-32h16c18 0 32-14 32-32v-16c0-18-14-32-32-32H32C14 80 0 66 0 48V32Z"
    clip-rule="evenodd"
  /></svg>
</template>
